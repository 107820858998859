@import "../../styles/variables";
@import "../../styles/mixins.scss";

.header {
  min-height: 90px;
  z-index: 4000;
  position: relative;
  background: $color-white;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  &__content {
    width: 100%;
    padding: 25px 120px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    @include respond-below(lg) {
      padding: 25px 80px;
    }

    @include respond-below(md) {
      padding: 16px 48px;
    }

    @include respond-below(sm) {
      padding: 16px;
    }

    &__logoWrap {
      display: flex;

      a {
        height: 30px;
      }
    }

    &__logo {
      height: 30px;

      @include respond-below(md) {
        width: 60px;
      }
    }

    &__logo2 {
      height: 30px;

      @include respond-below(md) {
        width: 115px;
      }
    }

    &__line {
      margin: 0 12px;
      width: 1px;
      border-radius: 1px;
      background: $color-gray-1;
      height: 22px;
      margin-top: 4px;

      @include respond-below(md) {
        margin: 0 6px;
        margin-top: 4px;
      }
    }

    &__tooltip {
      display: flex;
      flex-direction: column;
      font-style: normal;
      width: fit-content;
      padding: 4px;
      right: 16px !important;
      left: 0 !important;

      a {
        color: #0a84ff;
        white-space: nowrap;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        @include respond-below(md) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      span {
        margin-top: 4px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;

        @include respond-below(md) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

    &__phone {
      display: flex;
      align-items: center;
      cursor: pointer;

      path {
        stroke: $color-black;
      }

      &:hover {
        path {
          stroke: $color-black-60;
        }

        span {
          color: $color-black-60;
        }
      }

      svg {
        margin-right: 4px;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  &__content2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__img1 {
      position: relative;
      z-index: 2;
      width: 524px;
      margin-bottom: 12px;

      @include respond-below(xl) {
        width: 440px;
        margin-bottom: 8px;
      }

      @include respond-below(lg) {
        width: 340px;
        margin-bottom: 4px;
      }

      @include respond-below(md) {
        width: 262px;
      }

      @include respond-below(xs) {
        width: 250px;
      }
    }

    &__btn {
      display: flex;
      padding: 8px 16px;
      width: fit-content;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 16px;
      background-color: rgba(225, 18, 37, 1);
      position: relative;
      z-index: 2;
      color: #FFFFFF;
      border: 2px solid #FFFFFF;

      @include respond-below(lg) {
        font-size: 15px;
        line-height: 20px;
        padding: 6px 16px;
      }
    }
  }
}

.tooltip {
  @include respond-below(md) {
    right: 16px;
    left: auto !important;
  }

  &::after,
  &::before {
    content: none !important;
  }
}

[class*="__react_component_tooltip"] {
  box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
  border-radius: 16px !important;
}

.tooltip__action {
  position: absolute;
  top: 30px;
  right: 120px;
  z-index: 20;

  @include respond-below(lg) {
    right: 80px;
  }

  @include respond-below(md) {
    top: 21px;
    right: 48px;
  }

  @include respond-below(sm) {
    right: 16px;
  }
}


.main_image {
  width: 100%;
  height: auto;
  display: none;
  margin-top: -80px;

  @include respond-above(xs) {
    display: block;
  }
}

.main_image_mobile {
  width: 100%;
  height: auto;
  display: none;
  margin-top: -62px;

  @include respond-below(xs) {
    display: block;
  }
}

.logo_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;

  @include respond-above(md) {
    top: 80px;
  }

  @include respond-below(md) {
    top: 62px;
  }
}