@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 16px;
}

.container {
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	@include respond-below(lg) {
		max-width: 100%;
	}
}

.text {
	margin-top: 24px;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	color: $color-black-60;

	@include respond-below(md) {
		margin-top: 16px;
		font-size: 13px;
		line-height: 16px;
	}

	a {
		color: #0071e6;
	}
}

.arrow {
	position: absolute;
	left: -24px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	@include respond-below(lg) {
		left: -14px;
	}
	&_right {
		left: revert;
		right: -24px;
		@include respond-below(lg) {
			right: -14px;
		}
	}
}

.wrapper_single {
	display: grid;
	gap: 16px;
	grid-auto-flow: column;
	margin: 0 -16px;

	@include respond-above(md) {
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@include respond-below(md) {
		gap: 8px;
		padding: 0 20px;
	}
}

.draw_winners_wrapper {
	position: relative;
}

.gradient {
	position: absolute;
	top: 0;
	width: 100px;
	height: 132px;
}

.gradient_left {
	left: -20px;
	background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.gradient_right {
	right: -20px;
	background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.wrapper_double,
.wrapper_single {
	@include respond-below(md) {
		@include hide-scroll;
		overflow-x: scroll;
	}
}

.noneOverflow {
	@include respond-below(lg) {
		overflow: inherit !important;
	}
}

.block {
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	background: #ffffff;
	margin: 48px 0 92px;
	align-items: center;

	@include respond-below(lg) {
		padding: 0;
		margin: 24px 0 56px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__name {
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 46px;
		color: $color-black;
		text-align: center;

		@include respond-below(md) {
			font-size: 28px;
			line-height: 34px;
		}
	}

	&__body {
		margin-top: 40px;
		width: 100%;

		@include respond-below(md) {
			margin-top: 32px;
		}
	}
}

.small {
	max-width: 880px;
}

.card {
	background: #ffffff;
	border-radius: 16px;
	padding: 12px 16px;
	margin: auto 0;
	height: 100%;
	width: 324px;
	border: 1px solid $color-gray-4;

	@include respond-below(md) {
		width: 280px;
	}

	&__superDraws {
		position: relative;
		z-index: 1;

		@include respond-below(md) {
			width: 320px;
		}
	}

	&__winner {
		box-shadow: none;
		cursor: pointer;

		&::after {
			background: #f4f4f5;
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
		flex-wrap: wrap;

		&__left {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;
			color: #1c1c1e;
			flex-shrink: 0;
			margin-right: 5px;

			@include respond-below(md) {
				font-size: 15px;
				line-height: 20px;
			}
		}

		&__rigth {
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;
			color: #757576;

			@include respond-below(sm) {
				font-size: 13px;
				line-height: 16px;
			}

			&__blue {
				font-weight: 500;
				font-size: 15px;
				line-height: 20px;
				color: #0071e6;
				display: flex;
				align-items: center;

				@include respond-below(md) {
					font-size: 14px;
					line-height: 18px;
				}

				i {
					font-size: 12px;
					margin-left: 2px;
					transform: rotate(-90deg);
				}
			}
		}
	}
	&__body {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		color: #757576;
		display: flex;
		flex-direction: column;

		@include respond-below(md) {
			font-size: 14px;
			line-height: 18px;
		}

		span {
			margin-top: 4px;
			display: flex;
			flex-shrink: 0;
			white-space: nowrap;
		}

		&__super {
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;

			@include respond-below(sm) {
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
}

.padding {
	padding: 32px 14px;

	@include respond-below(md) {
		padding: 20px 0;
	}
}
.expanderWrap {
	[class*='block__body'] {
		flex-direction: column;
	}
}

.expander {
	width: 100%;
	background: $color-white;
	padding: 10px 0;

	&_margin {
		margin-bottom: 0;
	}
	i {
		color: $color-gray-1;
		transition: none;
	}
	&::after,
	&::before {
		content: none;
	}
	span {
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		color: $color-black;

		@include respond-below(md) {
			font-size: 15px;
			line-height: 20px;
		}
	}
	[class*='expander__header'] {
		[class*='expander__icon_expand'] {
			color: $color-black;
		}
		&:hover {
			i {
				color: $color-black;
			}
			span {
				color: $color-black;
			}
		}
	}
	&__desc {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: $color-black;

		@include respond-below(md) {
			font-size: 15px;
			line-height: 20px;
		}

		ul {
			margin: 0;
			padding: 0;
			padding-inline-start: 30px;
			margin-block: 0;
		}

		a,
		span {
			color: #0a84ff;
			cursor: pointer;
		}
	}
}

.prizesTitle {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	padding-top: 16px;
	position: relative;
	@media (max-width: 800px) {
		border-top: 1px solid #ececf0;
		padding-top: 24px;
	}

	&__icon {
		width: 64px;
		position: absolute;
		margin: -13px 0px 0px -275px;
		@include respond-below(sm) {
			margin: -21px 0px 0px -258px;
		}
	}

	&__text {
		color: #1c1c1e;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px; /* 131.25% */
	}
}

.prizes {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	@include respond-below(md) {
		flex-direction: column;
	}
}

.prizes__card {
	display: flex;
	flex: 1px;
	border-radius: 16px;
	box-shadow: 0 2px 6px 0 rgba(28, 28, 30, 0.1);
	align-items: center;

	@include respond-above(md) {
		padding: 24px 32px;
		gap: 32px;
	}

	@include respond-below(md) {
		flex-direction: column;
		padding: 24px;
		text-align: center;
		gap: 4px;
	}
}

.prizes__image {
	@include respond-above(md) {
		width: 230px;
		height: 160px;
	}
}

.prizes__description,
.prizes__title {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: rgba(117, 117, 118, 1);

	@include respond-below(md) {
		font-size: 20px;
		line-height: 24px;
	}
}

.prizes__amount {
	margin-top: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: rgba(117, 117, 118, 1);

	@include respond-below(md) {
		font-size: 14px;
		line-height: 18px;
	}
}

.prizes__title {
	color: rgba(28, 28, 30, 1);
}

.divider {
	@include respond-above(xs) {
		display: none;
	}

	@include respond-below(xs) {
		width: 200px;
		border-bottom: 1px solid $color-gray-4;
	}
}

.limited {
	max-width: 176px;
}

.brands {
	a {
		&:nth-child(5n) {
			.brands__card {
				margin-right: 0 !important;
			}
		}
	}

	&__block {
		display: grid;
		column-gap: 16px;
		grid-template-columns: repeat(5, 228px);
		padding: 0 16px;
		margin: 0 -16px;
		overflow-x: auto;
		@include hide-scroll;

		&::-webkit-scrollbar {
			width: 0;
		}

		@include respond-below(md) {
			padding: 0 20px;
			grid-auto-flow: column;
			column-gap: 8px;
			gap: 8px;
			grid-template-rows: repeat(2, 88px);
		}
	}

	&__card {
		flex: 1;
		background: #ffffff;
		border: 1px solid $color-gray-4;
		border-radius: 16px;
		margin-right: 0;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 104px;

		&:hover {
			border: 1px solid $color-gray-3;
		}

		@include respond-below(md) {
			height: 88px;
			width: 120px;
		}

		@include respond-below(sm) {
			margin-bottom: 8px;
		}

		img {
			width: 120px;

			@include respond-below(md) {
				width: 64px;
			}
		}
	}
	&__link {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		color: #757576;
		margin: 0 auto;
		padding-top: 16px;
		display: flex;
		width: fit-content;

		:hover {
			color: $color-black;
		}

		@include respond-below(md) {
			margin-top: 0;
			font-size: 15px;
			line-height: 20px;
		}
	}
}

.blocks_emall {
	@include respond-below(md) {
		grid-template-columns: repeat(17, 120px);
	}
}

.blocks_edostavka {
	@include respond-below(md) {
		grid-template-columns: repeat(18, 120px);
	}
}

.rules {
	display: flex;
	flex-direction: column;

	&__wrapper {
		margin-top: 64px;

		@include respond-below(md) {
			margin-top: 40px;
		}

		@include respond-below(xs) {
			margin-top: 24px;
		}
	}

	&__block {
		display: flex;
		gap: 32px;
		justify-content: space-between;

		@include respond-below(md) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;

		@include respond-below(md) {
			max-width: initial;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__badge {
			width: 48px;
			height: 48px;
			background: #f4f4f5;
			border-radius: 111px;
			margin-bottom: 16px;
			display: flex;
			align-items: center;
			justify-content: center;

			@include respond-below(sm) {
				width: 40px;
				height: 40px;
				margin-bottom: 12px;
			}

			&::after {
				content: attr(data-number);
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				color: #1c1c1e;

				@include respond-below(sm) {
					font-size: 17px;
					line-height: 22px;
				}
			}
		}

		&__title {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			color: $color-black;
			margin-bottom: 8px;

			@include respond-below(sm) {
				font-size: 17px;
				line-height: 22px;
			}
		}

		&__desc {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			text-align: center;
			color: $color-black-60;
			a {
				color: #0a84ff;
				cursor: pointer;
			}
			span {
				color: #0a84ff;
				cursor: pointer;
			}
			@include respond-below(md) {
				font-size: 15px;
				line-height: 20px;
			}
		}
	}

	&__link {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		color: $color-black-60;
		display: flex;
		width: fit-content;
		margin: 24px auto 0;

		:hover {
			color: $color-black;
		}

		i {
			margin-right: 8px;
		}

		@include respond-below(md) {
			margin-top: 32px;
			font-size: 15px;
			line-height: 20px;
		}
	}
}

.betterTogether {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -16px;
	text-align: center;
	span {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		color: #1c1c1e;
	}
	img {
		margin-top: 32px;
		height: 40px;
		width: auto;
	}
}

.tabs {
	padding: 0 20px;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;

	@include respond-below(md) {
		margin-bottom: 16px;
	}

	&__emall {
		transition: none;

		&:hover {
			color: #f4303b;
			transition: none;
		}
	}

	&__edostavka {
		transition: none;

		&:hover {
			color: #02b875;
			transition: none;
		}
	}

	&__tab {
		cursor: pointer;
		display: flex;
		width: fit-content;
		height: 42px;
		align-items: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
		color: #757576;
		border-bottom: 2px solid white;

		&:first-child {
			margin-right: 16px;
		}

		@include respond-below(md) {
			font-size: 15px;
			line-height: 20px;
		}

		&_active {
			border-bottom: 2px solid #757576;
		}
	}
}

.faq {
	position: relative;

	& > div {
		border-bottom: 1px solid $color-gray-4;

		&:last-child {
			border-bottom: none;
		}
	}
}
