@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.title {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include respond-above(md) {
    font-size: 20px;
    line-height: 24px;
  }

  @include respond-below(md) {
    font-size: 17px;
    line-height: 22px;
  }
}
