@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  min-height: 56px;
  background-color: $color-white;
  z-index: 10;

  @include respond-above(md) {
    padding: 16px 20px;
  }

  @include respond-below(md) {
    padding: 16px;
  }
}
