@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.body {
	display: flex;
	flex-direction: column;
}

.body_title {
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: $color-black;
	margin-bottom: 16px;
}

.body_content {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: $color-black-60;

	a {
		color: $color-black-60;
		text-decoration: underline;

		@include respond-above(md) {
			transition: color 0.3s;

			&:hover {
				color: $color-black;
			}
		}
	}
}

.ul {
	padding-left: 20px;
	font-size: 14px;
	line-height: 20px;

	li {
		margin-bottom: 2px;
	}
}

p {
	margin: 0;
}

.modal {
	width: 500px;
}

.toggle_body {
	border: 1px solid $color-gray-4;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	padding: 12px 16px;
	margin-bottom: 16px;
}

.toggle_title {
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	color: $color-black;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button:disabled {
		opacity: 0.6;
	}
}

.toggle_content,
.toggle_notice {
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: $color-black-60;
}

.toggle_notice {
	font-style: italic;
}

.footer {
	display: flex;
	align-items: center;
	gap: 8px;

	@include respond-below(md) {
		border-top: 0;
	}
}

.settings {
	background-color: transparent;
	border: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: $color-black-60;
	margin-top: 16px;
	text-decoration: underline;
	align-self: flex-start;
	padding: 0;
	cursor: pointer;

	@include respond-above(md) {
		transition: color 0.3s;

		&:hover {
			color: $color-black;
		}
	}
}

.button {
	flex: 1;
	min-width: none;
	border-radius: 50px;
	height: 45px;
	transition: transform 0.1s ease-out, color 0.15s linear, background-color 0.15s linear, border-color 0.15s linear;

	&.deny {
		border: none;

		&:hover {
			background-color: $color-light-gray;
		}

		&:active {
			background-color: $color-silver-gray;
		}
	}

	&:hover {
		background-color: $color-charcoal-gray;
	}

	&:active {
		background-color: $color-dark-charcoal;
		transform: scale(0.96);
	}
}

.modal {
	@include respond-below(md) {
		border-radius: 18px 18px 0 0;
		width: 100%;
	}

	@include respond-above(md) {
		border-radius: 18px;
	}

	height: auto;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	padding: 0;
}
