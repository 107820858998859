@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.footer {
  background-color: $color-white;

  @include respond-above(md) {
    padding: 16px 20px;
    margin-top: 8px;
    border-radius: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include respond-below(md) {
    border-radius: 16px 16px 0 0;
    padding: 8px 16px;
  }
}
