@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.body {
  flex: 1;
  overflow: auto;
  border-top: 1px solid $color-white;

  @include respond-above(md) {
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ececec;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $color-gray-stroke;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $color-black-60;
    }
  }
}

.body_scrolled {
  [class^="checkboxes_search_container"] {
    box-shadow: $shadow-top;
  }
}

.content {
  background-color: $color-white;
  display: flex;
  flex-direction: column;

  &_with_footer {
    @include respond-above(md) {
      margin-bottom: 86px;
    }
  }

  @include respond-above(md) {
    padding: 16px 20px 20px 20px;
  }

  @include respond-below(md) {
    padding: 8px 16px 16px;
  }
}
